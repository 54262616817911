<template>
  <div id="rebateWithdraw">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <div class="withDraw-box">
          <div class="withDraw-form">
            <div class="left">
              <el-steps direction="vertical">
                <el-step icon="red"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="red"></el-step>
              </el-steps>
            </div>
            <div class="right">
              <h3>{{ $t('withdraw.REBATE_WITHDRAW_REQUEST') }}</h3>
              <Top
                ref="top"
                :chooseWithdrawalType="chooseWithdrawalType"
                @withdrawFormChange="withdrawFormChange"
                @setCountryCode="getCountryCode"
                @setBankList="getBankList"
                @tabChange="tabChange"
              ></Top>
              <component
                :is="currentComponent"
                :dialog.sync="dialog"
                :userCountryCode="userCountryCode"
                :bankList="userBankList"
                :withdrawalType="withdrawForm.withdrawalType"
                :chooseWithdrawalType="chooseWithdrawalType"
                @formChange="formChange"
                @changeBankComponent="countryAUandIntel"
                ref="child"
              ></component>
              <VerificationCode ref="verificationCode" namespace="IB_WITHDRAW"></VerificationCode>
              <div class="submit-btn-box">
                <el-button class="btn-red" @click="submitForm('withdrawForm')" data-testid="submitWithdrawForm">
                  {{ $t('common.keys.SUBMIT') }}
                </el-button>
              </div>
              <vDialog :show.sync="dialog.show">
                <p>{{ dialog.text }}</p>
                <div class="btn-box">
                  <el-button class="btn-red" @click="confirm" data-testid="confirm">
                    {{ $t('common.button.confirm') }}
                  </el-button>
                </div>
              </vDialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from '@/components/rebateWithdraw/Top';
import VerificationCode from '@/components/rebateWithdraw/VerificationCode';
import Skrill from '@/components/rebateWithdraw/types/Skrill';
import Neteller from '@/components/rebateWithdraw/types/Neteller';
import UnionPayCps from '@/components/rebateWithdraw/types/UnionPayCps';
import Fasapay from '@/components/rebateWithdraw/types/Fasapay';
import BankTransfer from '@/components/rebateWithdraw/types/BankTransfer';
import Thailand from '@/components/rebateWithdraw/types/Thailand';
import Malaysia from '@/components/rebateWithdraw/types/Malaysia';
import Bitwallet from '@/components/rebateWithdraw/types/Bitwallet';
import Nigeria from '@/components/rebateWithdraw/types/Nigeria';
import Vietnam from '@/components/rebateWithdraw/types/Vietnam';
import Bitcoin from '@/components/rebateWithdraw/types/Bitcoin';
import USDT from '@/components/rebateWithdraw/types/USDT';
import Sticpay from '@/components/rebateWithdraw/types/Sticpay';
import Indonesian from '@/components/rebateWithdraw/types/Indonesian';
import India from '@/components/rebateWithdraw/types/India';
import Korea from '@/components/rebateWithdraw/types/Korea';
import PhpAndSA from '@/components/rebateWithdraw/types/PhpAndSA';
import Africa from '@/components/rebateWithdraw/types/Africa';
import PerfectMoney from '@/components/rebateWithdraw/types/PerfectMoney';
import Canada from '@/components/rebateWithdraw/types/Canada';
import { apiApplyWithdrawal } from '@/resource';
import mixin from '@/mixins';
import vDialog from '@/components/vDialog';
import signHelper from '@/util/signinHelper';

export default {
  name: 'RebateWithdraw',
  components: { Top, vDialog, VerificationCode },
  mixins: [mixin],
  data() {
    return {
      userId: this.$store.state.common.CUID,
      accountName: this.$store.state.common.userName,
      country: null,
      form: {},
      withdrawForm: {},
      chooseWithdrawalType: {},
      dialog: {
        show: false,
        text: '',
        method: ''
      },
      userCountryCode: null,
      userBankList: null,
      currentComponent: null,
      transferFlag: false,
      prevRoute: null,
      withdrawalTypeList: {
        2: BankTransfer,
        4: UnionPayCps,
        5: Thailand, // Thailand, Dropdown Bankname
        6: Malaysia, // Malaysia, Dropdown Bankname
        7: Fasapay,
        8: Vietnam, // Vietnam, Dropdown Bankname
        9: Nigeria,
        24: India,
        31: Skrill,
        32: Neteller,
        34: Bitcoin,
        35: Korea,
        36: USDT,
        37: Bitwallet,
        38: Sticpay,
        39: Indonesian, // Indonesia, Dropdown Bankname
        40: PhpAndSA, // Philippine, Dropdown Bankname
        42: PhpAndSA,
        50: Africa, // Uganda
        51: Africa, // Rwanda
        52: Africa, // Zambia
        53: Africa, // Congo
        54: Africa, // Cameroon
        55: Africa, // Burundi
        56: Africa, // Kenya
        57: Africa, // Ghana
        58: Africa, // Tanzania
        60: Canada,
        61: PerfectMoney
      }
    };
  },
  watch: {
    'withdrawForm.withdrawalType'(value) {
      this.country = null;
      this.chooseWithdrawalType = this.$refs.top.withdrawalTypes.find(f => f.id == value);
      this.transferFlag =
        this.chooseWithdrawalType && this.chooseWithdrawalType.withdrawSubTypes.length > 0 ? true : false;
      this.currentComponent = this.withdrawalTypeList[value];
    }
  },
  //get previous path
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    getCountryCode(code) {
      this.userCountryCode = code;
    },
    getBankList(bankList) {
      this.userBankList = bankList;
    },
    withdrawFormChange(form) {
      this.withdrawForm = form;
    },
    tabChange() {
      this.currentComponent = null;
      this.withdrawForm.withdrawalType = '';
    },
    formChange(form) {
      this.form = form;
    },
    submitForm() {
      const top = this.$refs.top.submitForm(),
        verCode = this.$refs.verificationCode.submitForm(),
        comp = this.$refs.child.submitForm(),
        vm = this;
      Promise.all([top, comp, verCode]).then(function(values) {
        vm.applyWithdraw();
      });
    },
    confirm() {
      if (this.dialog.method == 'noCard') this.goToClientPortalDrawDetail();
    },
    goToClientPortalDrawDetail() {
      signHelper.signOut('backToClientPortal', '/paymentDetails');
    },
    // 出金请求
    applyWithdraw() {
      let withdrawForm = { ...this.withdrawForm };
      let form = { ...this.form };
      let accountName = this.accountName;
      const getVerifyCodeComponent = this.$refs.verificationCode;
      let getVerifyCode = getVerifyCodeComponent.getFormDataOfChild();

      // 如果出金方式为 Fasapay,则accountName为bankName，不传用户名
      if (this.withdrawForm.withdrawalType === 7) {
        accountName = form.bankName;
      }

      // Canada, 如果出金方式为 bank transfer international
      if (this.userCountryCode == 4575 && this.country == 2 && this.withdrawForm.withdrawalType === 2) {
        form.importantNotes = `Transit Number: ${this.$refs.child.$refs.bankChild.canadaOnly.transitNumber}, Institution Number: ${this.$refs.child.$refs.bankChild.canadaOnly.institutionNumber}, notes: ${this.form.importantNotes}`;
      }

      apiApplyWithdrawal(
        {
          ...withdrawForm,
          ...form,
          ...getVerifyCode,
          qUserId: this.userId,
          accountName: accountName
        },
        this.token
      ).then(resp => {
        if (resp.data.code === 0) {
          this.$message.success(this.$t('withdraw.YourPaymentIsBeingProcessed'));
          this.bus.$emit('updateWithdrawHistory', true);
          this.$nextTick(function() {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        }
      });
    },
    countryAUandIntel(value) {
      this.country = parseInt(value);
      console.log(this.country);
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/rebateWithdraw.scss';
</style>
