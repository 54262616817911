<template>
  <el-form
    :model="withdrawForm"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
    label-position="top"
    :rules="rulesWithdraw"
    ref="withdrawForm"
  >
    <div class="form-list-inner">
      <p>{{ $t('common.keys.FROM') }}</p>
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('common.keys.rebateAccount')" prop="qAccount">
            <el-select v-model="withdrawForm.qAccount" @change="rebateAccountChange">
              <el-option
                v-for="item in accountList"
                :key="item.login"
                :label="item.login + ' - ' + item.balance + ' ' + item.currency"
                :value="item.login"
              ></el-option>
            </el-select>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="amtLabel" prop="amount">
            <numeric-input
              ref="numberInput"
              v-model="withdrawForm.amount"
              :currency="withdrawForm.currency"
              :precision="2"
            ></numeric-input>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form-list-inner to">
      <ul class="clearfix">
        <li class="topTo">
          <p>{{ $t('common.keys.TO') }}</p>
          <el-form-item :label="$t('withdraw.PleaseSelectDesiredWithdrawMethod')">
            <div class="mobile-title" v-if="!isBigDevicesMatches">{{ TABS_ITEMS[selectedTab].title }}</div>
            <el-tabs v-model="selectedTab" :stretch="true" type="card" v-show="isBigDevicesMatches">
              <el-tab-pane
                v-for="(tab, index) in Object.values(TABS_ITEMS)"
                :key="index"
                :label="tab.title"
                :name="tab.name"
              >
                <withdrawTypeList
                  class="item"
                  v-for="item in getType(tab.name)"
                  :key="item.id"
                  :type="item"
                  :name="tab.testId"
                  :selectedType="withdrawForm.withdrawalType"
                  :countryCode="countryCode"
                  @changeType="changeType"
                ></withdrawTypeList>
              </el-tab-pane>
            </el-tabs>
            <!-- mobile -->
            <el-tabs v-model="selectedTab" :stretch="true" type="card" v-show="!isBigDevicesMatches">
              <el-tab-pane v-for="(mobileTab, index) in Object.values(TABS_ITEMS)" :key="index" :name="mobileTab.name">
                <div slot="label">
                  <img
                    :src="
                      require(`@/assets/images/rebateWithdraw/${mobileTab.img}${
                        mobileTab.name === selectedTab ? '_active' : ''
                      }.png`)
                    "
                    :alt="mobileTab.name"
                    :data-testid="mobileTab.name"
                  />
                </div>
                <withdrawTypeList
                  class="item"
                  v-for="item in getType(mobileTab.name)"
                  :key="item.id"
                  :type="item"
                  :name="mobileTab.testId"
                  :selectedType="withdrawForm.withdrawalType"
                  :countryCode="countryCode"
                  @changeType="changeType"
                ></withdrawTypeList>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import withdrawTypeList from '@/components/rebateWithdraw/withdrawTypeList/withdrawTypeList.vue';
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiGetWithdrawalData, apiQueryRateUSDByTargetCurrency } from '@/resource';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'top',
  components: { NumericInput, withdrawTypeList },
  props: { chooseWithdrawalType: Object },
  data() {
    return {
      selectedTab: 'bankTransfer',
      TABS_ITEMS: {
        bankTransfer: {
          title: this.$t('common.withdrawChannel.banktransfer'),
          img: `bank_mobile`,
          name: `bankTransfer`,
          testId: 'bankWithdrawal-'
        },
        eWallet: {
          title: this.$t('common.withdrawChannel.E_WALLET'),
          img: `ew_mobile`,
          name: `eWallet`,
          testId: 'eWalletWithdrawal-'
        },
        cryptoCurrency: {
          title: this.$t('common.withdrawChannel.CRYPTOCURRENCY'),
          img: `crypto_mobile`,
          name: `cryptoCurrency`,
          testId: 'cryptoWithdrawal-'
        },
        localTransfer: {
          title: this.$t('common.withdrawChannel.LOCAL_TRANSFER'),
          img: `lt_mobile`,
          name: `localTransfer`,
          testId: 'localWithdrawal-'
        }
      },
      accountList: [],
      withdrawalTypes: [],
      withdrawalSupportedTypes: [],
      countryBankList: null,
      countryCode: null,
      availableBalance: '',
      withdrawForm: {
        qAccount: '',
        amount: '',
        currency: '',
        withdrawalType: ''
      },
      ngaCountryCode: 5796,
      canCountryCode: 4575,
      ngaSupportCurrencies: ['USD', 'USC'],
      UnionpaySupportCurrencies: ['USD', 'USC'],
      minLimit: this.$config.withdrawalDefaultAmountMin, // current minLimit
      apiAmountLimit: 0, // record amountLimit from props chooseWithdrawalType.amountLimit
      currentWidth: 0
    };
  },
  watch: {
    withdrawForm: {
      handler(val, oldVal) {
        this.$emit('withdrawFormChange', val);
      },
      deep: true
    },
    selectedTab: {
      handler(val, oldVal) {
        this.$emit('tabChange', val);
      }
    },
    chooseWithdrawalType: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.apiAmountLimit = newValue?.amountLimit;
          this.convertUSDToTargetCurrency(this.withdrawForm.currency);
        }
      }
    },
    'withdrawForm.currency': {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.convertUSDToTargetCurrency(newValue);
          if (this.withdrawForm.amount) this.$refs.withdrawForm.clearValidate();
        }
      }
    }
  },
  computed: {
    rulesWithdraw() {
      return {
        qAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.amt'),
            trigger: 'blur'
          },
          {
            validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (parseFloat(value) <= 0) {
                // CANADA ONLY
                if (this.chooseWithdrawalType?.id == '60') {
                  callback(
                    new Error(
                      this.$t('withdraw.formErrorMsg.amtCAD', {
                        minLimit: this.minLimit,
                        currency: 'CAD'
                      })
                    )
                  );
                } else {
                  callback(new Error(this.$t('withdraw.formErrorMsg.amt0')));
                }
              } else if (value < this.minLimit) {
                // CANADA ONLY
                if (this.chooseWithdrawalType?.id == '60') {
                  callback(
                    new Error(
                      this.$t('withdraw.formErrorMsg.amtCAD', {
                        minLimit: this.minLimit,
                        currency: 'CAD'
                      })
                    )
                  );
                } else {
                  callback(
                    new Error(
                      this.$t('withdraw.amtLarger', {
                        minLimit: this.minLimit,
                        currency: this.withdrawForm.currency
                      })
                    )
                  );
                }
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('withdraw.YourPaymentGreaterThanAvailableBalance')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ]
      };
    },
    amtLabel() {
      return this.withdrawForm.currency === 'USC'
        ? `${this.$t('common.keys.amount')}    ${this.$t('common.keys.actualAmonut')}= ${this.withdrawForm.amount /
            100} USD`
        : this.$t('common.keys.amount');
    },
    bankWithdrawalType() {
      let bankTransferList = [];
      const bankTransferSupportedTypes = this.withdrawalSupportedTypes.filter(f => f.category === 1);

      if (bankTransferSupportedTypes.length > 0) {
        bankTransferSupportedTypes.forEach(f => {
          if (f.id === 2) {
            if (
              !this.$config.allowIBTWithdrawal({
                reg: this.regulator,
                countryCode: this.$store.state.common.countryCode,
                type: 'WITHDRAW'
              })
            ) {
              return [];
            } else {
              bankTransferList.push(f);
            }
          }
        });
        return bankTransferList;
      } else {
        return this.withdrawalSupportedTypes.filter(f => f.category === 1);
      }
    },
    eWalletWithdrawalType() {
      return this.withdrawalSupportedTypes.filter(f => f.category === 3);
    },
    cryptoWithdrawalType() {
      return this.withdrawalSupportedTypes.filter(f => f.category === 4);
    },
    localWithdrawalType() {
      return this.withdrawalSupportedTypes.filter(f => f.category === 5);
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    isBigDevicesMatches() {
      return this.currentWidth > 700;
    }
  },
  mounted() {
    this.getWithdrawalData();
  },
  methods: {
    getWithdrawalData() {
      apiGetWithdrawalData({
        qUserId: this.$store.state.common.CUID
      }).then(resp => {
        if (resp.data.code === 0) {
          const accounts = resp.data.data.logins;
          this.withdrawalTypes = resp.data.data.withdrawalType;
          this.countryCode = resp.data.data.countryCode;
          this.accountList = accounts.filter(acc => acc.accountDealType === 3);
          this.countryBankList = resp.data.data.withdrawBankList;

          this.$emit('setCountryCode', this.countryCode);
          this.$emit('setBankList', this.countryBankList);
          this.withdrawalSupportedTypes = this.withdrawalTypes;
          if (this.accountList && this.accountList.length > 0) {
            this.$nextTick(() => {
              // 默认选中首页选中的账号
              this.$set(this.withdrawForm, 'qAccount', this.accountList[0].login);
              this.rebateAccountChange(this.accountList[0].login);
            });
          }
        }
      });
    },
    rebateAccountChange(val) {
      if (val === '') {
        return;
      }

      const [currAccountInfo] = this.accountList.filter(item => {
        return item.login === val;
      });

      if (currAccountInfo) {
        this.availableBalance = currAccountInfo.balance;
        this.withdrawForm.currency = currAccountInfo.currency;
      }

      this.withdrawForm.withdrawalType = null;

      this.filterWithdrawalSupportedTypes(this.withdrawForm.currency);

      this.$nextTick(() => {
        if (this.withdrawForm.amount) {
          this.$refs.withdrawForm.validateField('amount', valid => {
            if (!valid) {
              return false;
            }
          });
        }
      });
    },
    filterWithdrawalSupportedTypes(currency) {
      if (!this.UnionpaySupportCurrencies.includes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalTypes.filter(type => type.id !== 4);
      } else {
        this.withdrawalSupportedTypes = this.withdrawalTypes;
      }

      if (this.filterNgaWithdrawTypes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 2);
      }
      if (this.filterCanWithdrawTypes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 60);
      }
      // filter Perfect Money (id = 61)
      if (this.filterPerfectMoneyType(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 61);
      }
    },
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },
    isChangeBKImg(id) {
      //change svg nigeria bnak transfer
      return id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode;
    },
    filterNgaWithdrawTypes(currency) {
      return (
        this.regulator === 'svg' &&
        this.countryCode == this.ngaCountryCode &&
        !this.ngaSupportCurrencies.includes(currency)
      );
    },
    filterCanWithdrawTypes(currency) {
      return this.countryCode == this.canCountryCode && currency !== 'CAD';
    },
    filterPerfectMoneyType(currency) {
      const allowedCurrencies = ['USD', 'USC', 'EUR'];
      return currency ? !allowedCurrencies.includes(currency) : true;
    },
    convertUSDToTargetCurrency(currency, equalValue = this.$config.withdrawalDefaultAmountMin) {
      if (!this.$config.usdRateFromApiCurrencyList.includes(currency)) {
        this.minLimit = this.apiAmountLimit ? this.apiAmountLimit : equalValue;
        return;
      }

      return apiQueryRateUSDByTargetCurrency(currency)
        .then(res => {
          if (res.data.code === 0 && res.data.data) {
            this.minLimit = Math.ceil(equalValue * Number(res.data.data));
          } else {
            this.minLimit = equalValue;
          }
        })
        .catch(error => {
          this.minLimit = equalValue;
        });
    },
    observerCallBack(width) {
      this.currentWidth = width;
    },
    getType(name) {
      switch (name) {
        case 'bankTransfer':
          return this.bankWithdrawalType;
        case 'eWallet':
          return this.eWalletWithdrawalType;
        case 'cryptoCurrency':
          return this.cryptoWithdrawalType;
        case 'localTransfer':
          return this.localWithdrawalType;
      }
    },
    changeType(val) {
      this.withdrawForm.withdrawalType = val;
    }
  }
};
</script>
